import React, { useEffect, useState } from 'react'
import { View, Text, StyleSheet, ScrollView, TouchableOpacity} from 'react-native'
import { CheckBox, Input } from 'react-native-elements'
import { responsiveFontSize, responsiveHeight, responsiveWidth } from 'react-native-responsive-dimensions'

import { LoadProduct } from '../services/firebaseServices'
import colors from '../styles/colors'
import { getDataObject, storeDataObject } from '../utils/asyncStorageBase'
import { formatPrice } from '../utils/formatPrice'


const pathRefAdditional = '/Additional'
export default function ProductDetail({route, navigation}){
    const [product, setProduct] = useState(null)
    const [category, setCategory] = useState(null)
    const [additional, setAdditional] = useState(null)

    const [checkFlavor, setCheckFlavor] = useState([])
    const [checkSize, setCheckSize] = useState({})
    const [checkAdditional, setCheckAdditional] = useState([])

    const [total, setTotal] = useState(0)
    const [obs, setObs] = useState(null)
    const [cart, setCart] = useState(null)
    useEffect(()=>{
        const c = route.params.category;
        const p = route.params.product;

        setCategory(c);
        setProduct(p);

        getDataObject('cart').then(value => {
            setCart(value)
        })

        LoadProduct(pathRefAdditional,c.key, response =>{setAdditional(response)})
    },[])

    useEffect(()=>{
        console.log(checkSize.name)
    },[checkSize])

    // useEffect(()=>{console.log(checkFlavor)},[checkFlavor])

    return(
        <View style={styles.container}>
            <ScrollView>
                <Text style={styles.title}>{product?.name}</Text>
                <Text style={styles.description}>{product?.description}</Text>
                {product?.flavor !== undefined &&(
                    <View>
                    <Text style={styles.tipoText}>Sabor</Text> 
                    {product?.flavor.map((value,index) =>
                        <TouchableOpacity style={styles.containerInfo} onPress={()=>{
                            let check = [...checkFlavor];
                            const limit = parseInt(product.qtdFlavor)
                            const limp = check.filter(value => value !== undefined);
                            console.log(limp.length)
                            let _total = 0;
                            if(limp.length <= limit - 1 && check[index] === undefined){
                                    check[index] = {
                                        active: !check[index],
                                        name: value.name,
                                        price: value.price
                                    };
                                    _total = total + parseFloat(formatPrice(value.price));
                            }else{
                                check.splice(index,1)
                                _total = total - parseFloat(formatPrice(value.price));
                            }
                            setTotal(_total)
                            setCheckFlavor(check)
                        }}>
                            <View style={{width: '80%'}}>
                                <Text style={styles.nameInfo}>{value.name}</Text>
                                <Text style={styles.descriptionInfo}>{value.description}</Text>
                                {value.price !== '0,00' && (<Text style={styles.nameInfo}>+ R$ {value.price}</Text>)}
                            </View>
                            <CheckBox
                                center
                                checked={checkFlavor[index]?.active}
                                uncheckedIcon='circle'
                                uncheckedColor={colors.gray}
                                checkedColor={colors.red} 
                                size={responsiveFontSize(1.8)}
                                checkedIcon='check-circle'
                                onPress={()=>{
                                    let check = [...checkFlavor];
                                    const limit = parseInt(product.qtdFlavor)
                                    const limp = check.filter(value => value !== undefined);
                                    console.log(limp.length)
                                    let _total = 0;
                                    if(limp.length <= limit - 1 && check[index] === undefined){
                                            check[index] = {
                                                active: !check[index],
                                                name: value.name,
                                                price: value.price
                                            };
                                            _total = total + parseFloat(formatPrice(value.price));
                                    }else{
                                        check.splice(index,1)
                                        _total = total - parseFloat(formatPrice(value.price));
                                    }
                                    setTotal(_total)
                                    setCheckFlavor(check)
                                }}                                
                            />
                        </TouchableOpacity>    
                    )}
                </View>
                )}
                {product?.size !== undefined &&(
                    <View>
                        <Text style={styles.tipoText}>Opção</Text> 
                        {product?.size.map( (value, index) =>
                                value.active &&
                                <TouchableOpacity style={styles.containerInfo} onPress={()=>{
                                    let check = {};
                                    let _total = 0;
                                    let ultTotal = 0;
                                    if(checkSize.key !== index){
                                        if(checkSize.price !== undefined){
                                            ultTotal = parseFloat(checkSize.price);
                                        }

                                        check = {
                                            key: index,
                                            name: value.name,
                                            price: value.price
                                        };
                                        _total = total + parseFloat(formatPrice(value.price)) - ultTotal;
                                    }else{
                                        check = {}
                                        _total = total - parseFloat(formatPrice(value.price));
                                    }
                                    setTotal(_total)
                                    setCheckSize(check)
                                }}>
                                    <View style={{width: '80%'}}>
                                        <Text style={styles.nameInfo}>{value.name}</Text>
                                        <Text style={styles.descriptionInfo}>R${value.price}</Text>
                                    </View>
                                    <CheckBox
                                        center
                                        checked={checkSize.key === index}
                                        uncheckedIcon='circle'
                                        uncheckedColor={colors.gray}
                                        checkedColor={colors.red} 
                                        size={responsiveFontSize(1.8)}
                                        checkedIcon='check-circle'
                                        onPress={()=>{
                                            let check = {};
                                            let _total = 0;
                                            let ultTotal = 0;
                                            if(checkSize.key !== index){
                                                if(checkSize.price !== undefined){
                                                    ultTotal = parseFloat(checkSize.price);
                                                }
        
                                                check = {
                                                    key: index,
                                                    name: value.name,
                                                    price: value.price
                                                };
                                                _total = total + parseFloat(formatPrice(value.price)) - ultTotal;
                                            }else{
                                                check = {}
                                                _total = total - parseFloat(formatPrice(value.price));
                                            }
                                            setTotal(_total)
                                            setCheckSize(check)
                                        }}
                                    />
                                </TouchableOpacity>
                            
                        )}
                    </View>
                )}
                {additional !== null &&(
                    <View>
                        <Text style={styles.tipoText}>Adicionais</Text>
                        {additional.map((add,index) => 
                            <TouchableOpacity style={styles.containerInfo} onPress={()=>{
                                let check = [...checkAdditional];
                                let _total = 0;
                                if(check[index] === undefined){
                                    check[index] = {
                                        active: !check[index],
                                        name: add.name,
                                        price: add.price
                                    };
                                    _total = total + parseFloat(formatPrice(add.price));
                                }else{
                                    check.splice(index,1)
                                    _total = total - parseFloat(formatPrice(add.price));
                                }
                                setTotal(_total)
                                setCheckAdditional(check)
                            }}>
                                <View style={{width: '80%'}}>
                                    <Text style={styles.nameInfo}>{add.name}</Text>
                                    <Text style={styles.descriptionInfo}>R${add.price}</Text>
                                </View>
                                <CheckBox
                                    center
                                    checked={checkAdditional[index]?.active}
                                    uncheckedIcon='circle'
                                    uncheckedColor={colors.gray}
                                    checkedColor={colors.red}
                                    size={responsiveFontSize(1.8)}
                                    checkedIcon='check-circle'
                                    onPress={()=>{
                                        let check = [...checkAdditional];
                                        let _total = 0;
                                        if(check[index] === undefined){
                                            check[index] = {
                                                active: !check[index],
                                                name: add.name,
                                                price: add.price
                                            };
                                            _total = total + parseFloat(formatPrice(add.price));
                                        }else{
                                            check.splice(index,1)
                                            _total = total - parseFloat(formatPrice(add.price));
                                        }
                                        setTotal(_total)
                                        setCheckAdditional(check)
                                    }}
                                />
                            </TouchableOpacity>
                        )}
                    </View>
                )}
                <Input
                    label='Observações'
                    labelStyle={{color: 'black', fontSize: responsiveFontSize(1.8)}}
                    inputStyle={{fontSize: responsiveFontSize(1.8)}}
                    placeholder='Ex: Retirar a cebola'
                    containerStyle={styles.obs}
                    onChangeText={ text => setObs(text)}
                />
            </ScrollView>
            {checkSize.name !== undefined &&(
                <TouchableOpacity style={styles.btnAddPedido} onPress={()=>{
                    const limpAdditional = checkAdditional.filter(value => value !== undefined)
                    const limpFlavor = checkFlavor.filter(value => value !== undefined)
                    const flavor = limpFlavor.filter(value => value !== undefined)
                    
                    let order = {
                        product: product.name,
                        flavor,
                        size: checkSize,
                        additional: limpAdditional,
                        obs,
                        total
                    }

                    let _cart = []

                    cart !== null &&(_cart = cart);
                    console.log(cart)
                    _cart.push(order)
                    
                    storeDataObject('cart',_cart)
                    .then(navigation.navigate('Home'));

                    
                }}>
                    <Text style={styles.btnAddPedidoText}>Adicionar pedido</Text>
                    <Text style={styles.btnAddPedidoPrice}>R${total.toFixed(2)}</Text>
                </TouchableOpacity>
            )}
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        width: responsiveFontSize(49),
        paddingTop: responsiveFontSize(5),
        alignSelf: 'center',
        backgroundColor: 'white'
    },
    title: {
        fontSize: responsiveFontSize(3),
        textAlign: 'center',
        marginBottom: responsiveFontSize(5),
        paddingHorizontal: responsiveFontSize(3)
    },
    description: {
        fontSize: responsiveFontSize(1.8),
        paddingHorizontal: responsiveFontSize(3),
        marginBottom: responsiveFontSize(3)
    },
    tipoText: {
        color: colors.black,
        fontWeight: 'bold',
        fontSize: responsiveFontSize(2),
        backgroundColor: colors.gray,
        textAlignVertical: 'center',
        alignContent: 'center',
        padding: responsiveFontSize(3)
    },
    containerInfo: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingHorizontal: responsiveWidth(5),
        paddingVertical: responsiveWidth(3),
        borderBottomWidth: 0.5,
        borderBottomColor: colors.gray
    },
    nameInfo: {
        fontSize: responsiveFontSize(1.8),
        marginTop: responsiveFontSize(1)
    },
    descriptionInfo: {
        fontSize: responsiveFontSize(1.5),
        marginTop: responsiveFontSize(0.5)
    },
    btnAddPedido: {
        flexDirection: 'row',
        width: responsiveFontSize(45),
        height: responsiveFontSize(5),
        backgroundColor: colors.red,
        borderRadius: responsiveFontSize(0.5),
        alignSelf: 'center',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: responsiveFontSize(3),
        marginBottom: responsiveFontSize(3)
    },
    btnAddPedidoText: {
        fontSize: responsiveFontSize(1.8),
        color: 'white'
    },
    btnAddPedidoPrice: {
        fontSize: responsiveFontSize(1.8),
        color: 'white',
        backgroundColor: colors.redBack,
        padding: responsiveWidth(0.5),
        borderRadius: 3
    },
    obs: {
        width: responsiveFontSize(45),
        paddingHorizontal: responsiveFontSize(3)
    }
})