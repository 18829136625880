import { useFocusEffect } from "@react-navigation/native";
import React, { useCallback, useEffect, useState } from "react";
import { View, Text, StyleSheet, TouchableOpacity, Alert} from 'react-native'
import { Input } from "react-native-elements";
import { responsiveFontSize, responsiveWidth } from "react-native-responsive-dimensions";
import { firebaseAuth } from "../../firebase";
import AlertModal from "../components/alertModal";
import LoadingModal from "../components/loadingModal";
import { loadUser, saveUser } from "../services/firebaseServices";
import colors from "../styles/colors";
import { getDataObject, storeDataObject } from "../utils/asyncStorageBase";
import { formatNumberPhone } from "../utils/formatNumberPhone";

export default function AddressUser({navigation}){
    const[user, setUser] = useState(null)
    const[rua, setRua] = useState('')
    const[numero, setNumero] = useState('')
    const[bairro, setBairro] = useState('')
    const[referencia, setReferencia] = useState('')

    const[alertModal, setAlertModal] = useState(false)
    const[loadingModal, setLoadingModal] = useState(false)

    useFocusEffect(
        useCallback(()=>{
            console.log('addressview')
            if(firebaseAuth.currentUser !== null && firebaseAuth.currentUser !== undefined)
            loadUser('/User', firebaseAuth.currentUser.uid, response => setUser(response))

            return ()=> console.log('saiuaddress')
        },[])
    )

    useEffect(()=>{
        if(user !== null){
            if(user.address !== null && user.address !== undefined){
                const {rua,numero,bairro,referencia} = user?.address;
                setRua(rua);
                setNumero(numero);
                setBairro(bairro);
                setReferencia(referencia);
            }
        }
    },[user])

    function saveAddress(){
        if(rua !== '' && numero !== '' && bairro !== ''){
            setLoadingModal(true)
            let address = {
                rua,
                numero,
                bairro,
                referencia
            }
            
            saveUser('/User', firebaseAuth.currentUser.uid, {address}, callback =>{setLoadingModal(false); navigation.goBack()})
        }else{
            setAlertModal(!alertModal)
        }
    }
    return(
        <View style={styles.container}>
            <Text style={styles.txtTitle}>Vamos começar 😍</Text>
            <Text style={styles.txtSubTitle}>Informe suas informações abaixo para começar.</Text>
            <Input 
                label='Logradouro:'
                placeholder='Rua, Avenida'
                keyboardType={'default'}
                placeholderTextColor={colors.gray}
                onChangeText={setRua}
                value={rua}/>
            <View style={styles.horizontal}>
                <Input 
                    containerStyle={styles.inputName}
                    label='Número:'
                    placeholder='Digite o número'
                    keyboardType={'phone-pad'}
                    maxLength={4}
                    placeholderTextColor={colors.gray}
                    onChangeText={setNumero}
                    value={numero}/>
                <Input 
                    containerStyle={styles.inputName}
                    label='Bairro:'
                    placeholder='Digite seu bairro'
                    keyboardType={'default'}
                    placeholderTextColor={colors.gray}
                    onChangeText={setBairro}
                    value={bairro}/>
            </View>
            <Input
                label='Ponto de Referência:'
                placeholder='digite a referência'
                keyboardType={'default'}
                placeholderTextColor={colors.gray}
                onChangeText={setReferencia}
                value={referencia}/>
            <TouchableOpacity style={styles.btn} onPress={()=>{saveAddress()}}>
                <Text style={styles.btnTxt}>Confirmar</Text>
            </TouchableOpacity>
            <AlertModal visible={alertModal}
                title={'Ah não 🤔'}
                msg={'Verifique se você preencheu todos os campos e tente novamente. 😉'}
                txtBtnCancel={''}
                txtBtnConfirm={'Ok'}
                callback={(value) =>{
                    if(value === 'confirmar'){ 
                        setAlertModal(!alertModal)
                    }else{
                        setAlertModal(!alertModal)
                    } 
                }}
            
            />
            <LoadingModal visible={loadingModal}
                msg='Aguarde...'
            />
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        backgroundColor: 'white',
        paddingHorizontal: responsiveWidth(5)
    },
    horizontal: {
        flexDirection: 'row', 
        justifyContent: 'center',
        alignItems: 'center'
    },
    txtTitle: {
        fontSize: responsiveFontSize(4),
        marginTop: responsiveWidth(5)
    },
    txtSubTitle: {
        fontSize: responsiveFontSize(1.8),
        color: colors.gray2,
        marginTop: responsiveWidth(2),
        marginBottom: responsiveWidth(5)
    },
    btn: {
        width: responsiveWidth(90),
        height: responsiveWidth(12),
        backgroundColor: colors.red,
        borderRadius: 5,
        alignSelf: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        paddingHorizontal: responsiveWidth(5)
    },
    btnTxt: {
        color: 'white',
        fontSize: responsiveFontSize(1.8),
        fontWeight: 'bold'
    },
    inputName: {
        width: '33%'
    }
})