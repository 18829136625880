import React, { useCallback, useEffect, useState } from "react";
import { View, Text, StyleSheet, TouchableOpacity, FlatList, ScrollView, Platform} from 'react-native';
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { responsiveFontSize, responsiveHeight, responsiveWidth } from "react-native-responsive-dimensions";
import { CheckBox } from "react-native-elements";
import { useFocusEffect } from "@react-navigation/native";
import { format} from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import * as Linking from 'expo-linking';


import { formatNumberPhone } from "../utils/formatNumberPhone";
import { LoadDados, loadUser, saveOrder } from "../services/firebaseServices";
import { getDataObject, removeItem } from "../utils/asyncStorageBase";
import { generateUUID } from "../utils/generateUID";
import colors from "../styles/colors";
import AlertModal from "../components/alertModal";
import AlertInputModal from "../components/alertInputModal";
import LoadingModal from "../components/loadingModal";
import { formatPrice } from "../utils/formatPrice";
import AlertInputMesa from "../components/alertInputMesa";
import { firebaseAuth } from "../../firebase";

const pathRef = '/Business/Dados';

export default function CartDetail({navigation,route}){
    const[user, setUser] = useState(null)
    //business
    const[business, setBusiness] = useState(null)
    const[enderecoEstabelecimento, setEnderecoEstabelecimento] = useState(null)
    const[payment, setPayment] = useState(null)
    const[service, setService] = useState(null)

    const[select, setSelect] = useState(1);
    const[selectModeDelivery, setModeDelivery] = useState({})
    const[selectPaymentMode, setSelectPAymentMode] = useState({})
    const[endereco, setEndereco] = useState(null);
    const[cart, setCart] = useState(null);
    const[nMesa, setNMesa] = useState('');

    const[totalF, setTotalF] = useState(0)
    const[subTotalF, setsubTotalF] = useState(0)
    const[taxaEntrega, setTaxaEntrega] = useState(0)
    const[moneyCliente, setMoneyCliente] = useState('0')

    const[alertModal, setAlertModal] = useState(false)
    const[alertInput, setAlertInput] = useState(false)
    const[loadingModal, setLoadingModal] = useState(false)
    const[alertInputMesa, setAlertInputMesa] = useState(false)

    
    useFocusEffect(
        useCallback(()=>{
            console.log('vendo')
            if(firebaseAuth.currentUser !== null && firebaseAuth.currentUser !== undefined)
            loadUser('/User', firebaseAuth.currentUser.uid, response => setUser(response))

            return ()=> console.log('saiu')
        },[])
    )

    useEffect(()=>{
       const {total,subTotal,cart} = route.params;
        setTotalF(total)
        setsubTotalF(subTotal)
        setCart(cart)
        LoadDados(pathRef, response => setBusiness(response))
    },[])

    useEffect(()=>{
        if(business !== null){
            setEnderecoEstabelecimento(business.endereco)
            setPayment(business.typeOfPayment)
            setService(business.typeOfService)
        }
    },[business])
    
    useEffect(()=>{
        checkAddress()
    },[selectModeDelivery,user])

    useEffect(()=>{
        if(taxaEntrega !== 0){
            let t = subTotalF + taxaEntrega;
            setTotalF(t)
        }else if(subTotalF !== 0){
            setTotalF(subTotalF)
        }
    },[taxaEntrega])

    useEffect(()=>{console.log(moneyCliente)},[moneyCliente])

    function checkAddress(){
        if(selectModeDelivery.name === 'Entrega' && selectModeDelivery.name !== undefined){
            setTaxaEntrega(parseFloat(formatPrice(business.taxaEntrega)))
            setEndereco(user.address)
        }else if(selectModeDelivery.name !== undefined){
            setTaxaEntrega(0)
            setEndereco(enderecoEstabelecimento)
        }else{
            setEndereco(null)
        }
        if(selectModeDelivery.name === 'Consumir no local' && selectModeDelivery.name !== undefined){
            setAlertInputMesa(true)
        }
    }
    function renderItem(item,index){
        return item.checked &&(
            <TouchableOpacity style={styles.btnMode} onPress={() => {
                if(user !== null){
                    select !== 2 && setSelect(2)
                    let check = {};
                    if(selectModeDelivery.key !== index){
                        check = {
                            key: index,
                            name: item.method
                        };
                    }
                    setModeDelivery(check)
                }else{
                    setAlertModal(!alertModal)
                }
            }}>
                <Text style={styles.btnModeTxt}>{item.method}</Text>
                <CheckBox
                    center
                    checked={selectModeDelivery.key === index}
                    uncheckedIcon='circle'
                    uncheckedColor={colors.gray}
                    checkedColor={colors.red} 
                    checkedIcon='check-circle'
                />
            </TouchableOpacity>
        )
    }
    function renderPaymentItem(item,index){
        return(
            <TouchableOpacity style={styles.btnMode} onPress={() => {
                if(user !== null){

                    select !== 4 && setSelect(4)
                    let check = {};
                    if(selectPaymentMode.key !== index){
                        check = {
                            key: index,
                            name: item.method
                        };
                    }
                    setSelectPAymentMode(check)
                    if(item.method === 'Dinheiro'){
                        setAlertInput(!alertInput)
                    }
                }else{
                    setAlertModal(!alertModal)
                }
            }}>
                <Text style={styles.btnModeTxt}>{item.method}</Text>
                <CheckBox
                    center
                    checked={selectPaymentMode.key === index}
                    uncheckedIcon='circle'
                    uncheckedColor={colors.gray}
                    checkedColor={colors.red} 
                    checkedIcon='check-circle'
                />
            </TouchableOpacity>
        )
    }

    function renderAddress(){
        if(endereco !== null && endereco !== undefined){
            return(
                <View style={{flexDirection: 'row',justifyContent: 'space-between'}}>  
                    <View>
                        <Text style={styles.txtAddress}>{`${endereco.rua}, ${endereco.numero}, ${endereco.bairro}`}</Text>
                        <Text style={styles.txtAddress}>{endereco.referencia}</Text>
                    </View>
                    {selectModeDelivery.name === 'Entrega' &&(
                        <TouchableOpacity style={styles.btnEdit} onPress={()=>{
                            if(user !== null){
                                select !== 3 && setSelect(3)
                                navigation.navigate('AddressUser')
                            }else{
                                setAlertModal(!alertModal)
                            }

                        }}>
                            <Text>Editar</Text>
                        </TouchableOpacity>
                    )}
                    {/* ver no mapa se escolher ir buscar */}
                    {selectModeDelivery.name !== 'Entrega' &&(
                        <TouchableOpacity style={styles.btnMapa} onPress={()=>{
                            if(user !== null){
                                select !== 3 && setSelect(3)
                                openMaps()
                            }else{
                                setAlertModal(!alertModal)
                            }

                        }}>
                            <MaterialCommunityIcons name="google-maps" size={responsiveFontSize(3)} color={colors.red} />
                            <Text style={styles.btnMapaTxt}>Ver no Mapa</Text>
                        </TouchableOpacity>
                    )}
                </View>
            )
        }
        if(endereco === undefined && selectModeDelivery.name === 'Entrega'){
            return(
                <TouchableOpacity style={styles.btnCreateAddress} onPress={()=>{
                    navigation.navigate('AddressUser')
                }}>
                    <Text style={styles.btnCreateAddressTxt}>Cadastrar Endereço</Text>
                </TouchableOpacity>
            )
        }
        if(selectModeDelivery.name === undefined){
            return(
                <Text>Seleção do endereço ou unidade</Text>
            )
        }
    }

    function openMaps(){
        const { lat, long} = enderecoEstabelecimento;
        const scheme = Platform.select({ ios: 'maps:0,0?q=', android: 'geo:0,0?q=', web: 'https://maps.google.com/?q='});
        const latLng = `${lat},${long}`;
        const url = Platform.select({
            ios: `${scheme}${latLng}`,
            android: `${scheme}${latLng}`,
            web: `${scheme}${latLng}`
        });


        Linking.openURL(url); 
    }
    return(
        <ScrollView style={styles.container}>
            {user !== null &&(
                <View style={styles.containerChangeAccount} onStartShouldSetResponder={()=>{navigation.navigate('Login')}}>
                    <Text style={styles.txtChange}>{`Não é ${user.name}? `}</Text>
                    <Text style={styles.txtChangeClick}>Clique aqui</Text>
                </View>
            )}
            <View style={styles.horizontal}>
                <Text style={select === 1 ? styles.txtNumActive : styles.txtNum}>1</Text>
                <Text style={select === 1 ? styles.txtLabelActive : styles.txtLabel}>Identificação</Text>
            </View>
            <View style={styles.containerIdentity} onStartShouldSetResponder={()=> {select !== 1 && setSelect(1)}}>
                {user !== null ? (
                    <>
                        <MaterialCommunityIcons name="account-circle" size={60} color={colors.red} />
                        <View style={styles.containerInfoIdentity}>
                            <Text style={styles.txtName}>{user.name}</Text>
                            <Text style={styles.txtPhone}>{formatNumberPhone(user.phone)}</Text>
                        </View>
                        <TouchableOpacity style={styles.btnEdit} onPress={()=>{navigation.navigate('User')}}>
                            <Text>Editar</Text>
                        </TouchableOpacity>
                    </>
                ):(
                    <View style={styles.containerBtn}>
                        <TouchableOpacity style={styles.btnLogin} onPress={()=>{navigation.navigate('Login')}}>
                            <Text style={styles.btnTxt}>Login</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={styles.btnCriar} onPress={()=>{navigation.navigate('User')}}>
                            <Text style={styles.btnTxt}>Criar</Text>
                        </TouchableOpacity>
                    </View>
                )}
            </View>

            <View style={styles.horizontal}>
                <Text style={select === 2 ? styles.txtNumActive : styles.txtNum}>2</Text>
                <Text style={select === 2 ? styles.txtLabelActive : styles.txtLabel}>Selecione o Modo de Entrega</Text>
            </View>
            <FlatList
                data={service}
                keyExtractor={(item,index) => index}
                renderItem={({item,index}) => renderItem(item,index)}
                contentContainerStyle={{backgroundColor: 'white', paddingTop: responsiveWidth(5)}}
                style={{maxHeight: responsiveFontSize(25)}}
            />

            <View style={styles.horizontal}>
                <Text style={select === 3 ? styles.txtNumActive : styles.txtNum}>3</Text>
                <Text style={select === 3 ? styles.txtLabelActive : styles.txtLabel}>Endereço ou Unidade de Retirada</Text>
            </View>
            <View style={styles.containerAddress} onStartShouldSetResponder={()=> {
                if(user !== null){
                    select !== 3 && setSelect(3)
                }else{
                    setAlertModal(!alertModal)
                }
                }}>
                {renderAddress()}
            </View>

            <View style={styles.horizontal}>
                <Text style={select === 4 ? styles.txtNumActive : styles.txtNum}>4</Text>
                <Text style={select === 4 ? styles.txtLabelActive : styles.txtLabel}>Pagamento</Text>
            </View>
            <FlatList
                data={payment}
                keyExtractor={(item,index) => index}
                renderItem={({item,index}) => renderPaymentItem(item,index)}
                contentContainerStyle={{backgroundColor: 'white', paddingTop: responsiveWidth(5)}}
                style={{maxHeight: responsiveHeight(30)}}
            />

            <View style={styles.containerTotal}>
                <View style={styles.containerInfoPrice}>
                    <Text style={styles.txtPriceInfo}>SubTotal</Text>
                    <Text style={styles.txtPriceInfo}>R$ {subTotalF.toFixed(2)}</Text>
                </View>
                <View style={styles.containerInfoPrice}>
                    <Text style={styles.txtPriceInfo}>Taxa de entrega</Text>
                    <Text style={styles.txtPriceInfo}>R$ {taxaEntrega.toFixed(2)}</Text>
                </View>
                <View style={styles.containerInfoPrice}>
                    <Text style={styles.txtTotal}>Total</Text>
                    <Text style={styles.txtTotal}>R$ {totalF.toFixed(2)}</Text>
                </View>
            </View>
            {selectModeDelivery.name !== undefined && selectPaymentMode.name !== undefined &&(
            <TouchableOpacity style={styles.btnNext} onPress={()=>{
                setLoadingModal(true)
                const id = generateUUID(8);
                let troco = moneyCliente !== '0' ? parseFloat(moneyCliente).toFixed(2) - totalF : 0;

                let historyStatus = [
                    {
                        status: 'Pendente',
                        time: format(new Date(), 'HH:mm',{locale: ptBR})
                    }
                ]
                let order = {
                    userName: user.name,
                    userPhone: user.phone,
                    deliveryMode: selectModeDelivery.name,
                    paymentMode: selectPaymentMode.name,
                    address: endereco,
                    total: totalF,
                    subTotal: subTotalF,
                    taxaEntrega,
                    cart: cart,
                    status: 'Pendente',
                    idOder: id,
                    thing: troco,
                    historyStatus,
                    create: new Date().getTime(),
                    pago: false,
                    nMesa
                }

                console.log(order)
                saveOrder('/Order',order, response => { 
                    response !== null && removeItem('cart')
                        .then(()=>{
                            setLoadingModal(false)
                            navigation.navigate('SucessOrder',{id: id,user: user, order: order})
                        })
                })
                
            }}>
                    <Text style={styles.btnNextTxt}>Confirmar</Text>
            </TouchableOpacity>
            )}
            <AlertModal visible={alertModal}
                title={'Ah não 🤔'}
                msg={'Para continuar você precisar fazer login ou criar a sua conta. 😉'}
                txtBtnCancel={''}
                txtBtnConfirm={'Ok'}
                callback={(value) =>{
                    if(value === 'confirmar'){ 
                        setAlertModal(!alertModal)
                    }else{
                        setAlertModal(!alertModal)
                    } 
                }}
            
            />
            <AlertInputModal visible={alertInput}
                title='Precisa de Troco'
                msg={`Informe a quantidade de dinheiro que vai pagar para calcular o seu troco.`}
                txtBtnCancel={"Não, obrigado."}
                txtBtnConfirm={'Confirmar'}
                callback={(value) =>{
                    if(value === 'confirmar'){ 
                        setAlertInput(!alertInput)
                    }else{
                        setAlertInput(!alertInput)
                    } 
                }}
                responseInput={(value) => value === '' ? setMoneyCliente('0') : setMoneyCliente(value)}
            />
            <LoadingModal visible={loadingModal}
                msg='Aguarde...'
            />
            <AlertInputMesa
                visible={alertInputMesa}
                title={'Mesa'}
                msg={`Para continuar, aponte para o qr code que tenha o numero da sua mesa.`}
                txtBtnCancel={'Cancelar'}
                callback={(value, data) =>{
                    setAlertInputMesa(false)
                    if(value === 'cancelar'){
                        setModeDelivery({})
                    }
                    if(value === 'confirmar'){
                        setNMesa(data)
                        console.log('Mesa: '+data)
                    }
                }}
            />
        </ScrollView>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        paddingHorizontal: responsiveWidth(5),
        paddingTop: responsiveWidth(5)
    },
    containerIdentity: {
        flexDirection: 'row',
        backgroundColor: 'white',
        padding: responsiveWidth(5),
        justifyContent: 'space-between',
        borderRadius: 5
    },
    containerChangeAccount: {
        flexDirection: 'row',
        position: 'absolute',
        alignItems: 'center',
        right: 0,
        marginRight: responsiveWidth(2)
    },
    containerInfoIdentity: {
        justifyContent: 'center'
    },
    containerAddress:{
        backgroundColor: 'white',
        padding: responsiveWidth(5),
        borderRadius: 5
    },
    containerTotal: {
        marginTop: responsiveWidth(5),
        padding: responsiveWidth(5),
        backgroundColor: 'white'
    },
    containerInfoPrice: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    containerBtn: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },
    horizontal: {
        flexDirection: 'row',
        alignContent: 'center',
        alignItems: 'center',
        marginLeft: '2%',
        bottom: -15,
        zIndex: 2000
    },
    txtName: {
        fontSize: responsiveFontSize(2.2),
        fontWeight: 'bold'
    },
    txtPhone: {
        fontSize: responsiveFontSize(1.8),
        color: colors.gray2
    },
    btnEdit: {
        width: responsiveWidth(15),
        height: responsiveWidth(6),
        borderWidth: 1,
        borderRadius: 20,
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center'
    },
    txtNum: {
        paddingHorizontal: '2%',
        paddingVertical: '0.9%',
        backgroundColor: 'white',
        color: colors.red,
        borderColor: colors.red,
        borderWidth: 1.5,
        borderRadius: 100,
        textAlign: 'center',
        zIndex: 2000
    },
    txtNumActive: {
        paddingHorizontal: '2%',
        paddingVertical: '0.9%',
        backgroundColor: colors.red,
        color: 'white',
        borderColor: 'white',
        borderWidth: 1.5,
        borderRadius: 100,
        zIndex: 2000
    }, 
    txtLabel: {
        paddingHorizontal: responsiveWidth(2),
        backgroundColor: 'white',
        color: colors.red,
        borderColor: colors.red,
        borderWidth: 1.5,
        borderRadius: 5,
        left: -8
    },
    txtLabelActive: {
        paddingHorizontal: responsiveWidth(2),
        backgroundColor: colors.red,
        color: 'white',
        borderColor: 'white',
        borderWidth: 1.5,
        borderRadius: 5,
        left: -8
    },

    btnMode: {
        flexDirection: 'row',
        backgroundColor: 'white',
        paddingHorizontal: responsiveWidth(5),
        justifyContent: 'space-between',
        alignItems: 'center',
        borderRadius: 5
    },
    txtPriceInfo: {
        fontSize: responsiveFontSize(1.6),
        color: colors.gray2,
        marginTop: responsiveWidth(2),
        marginHorizontal: responsiveWidth(3)
    },
    txtTotal: {
        fontSize: responsiveFontSize(2),
        fontWeight: 'bold',
        marginTop: responsiveWidth(2),
        marginHorizontal: responsiveWidth(3),
        color: 'black'
    },
    btnNext: {
        width: responsiveWidth(90),
        height: responsiveWidth(12),
        backgroundColor: colors.red,
        borderRadius: 5,
        alignSelf: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        paddingHorizontal: responsiveWidth(5),
        marginVertical: responsiveWidth(5)
    },
    btnNextTxt: {
        color: 'white',
        fontSize: responsiveFontSize(2),
        fontWeight: 'bold'
    },
    btnLogin: {
        backgroundColor: colors.zapColor,
        width: responsiveWidth(30),
        height: responsiveWidth(10),
        borderRadius: 5,
        marginHorizontal: responsiveWidth(5),
        alignItems: 'center',
        justifyContent: 'center'
    },
    btnCriar: {
        backgroundColor: colors.red,
        width: responsiveWidth(30),
        height: responsiveWidth(10),
        borderRadius: 5,
        marginHorizontal: responsiveWidth(5),
        alignItems: 'center',
        justifyContent: 'center'
    },
    btnTxt: {
        color: 'white',
        fontSize: responsiveFontSize(1.8),
        fontWeight: 'bold'
    },
    txtChange: {
        color: 'black',
        fontSize: responsiveFontSize(1.8),

    },
    txtChangeClick: {
        color: colors.red,
        fontSize: responsiveFontSize(1.8)
    },
    btnCreateAddress: {
        width: responsiveWidth(50),
        height: responsiveWidth(10),
        backgroundColor: colors.red,
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center'
    },
    btnCreateAddressTxt: {
        color: 'white',
        fontSize: responsiveFontSize(1.8),
        fontWeight: 'bold'
    },
    btnMapa: {
        paddingHorizontal: responsiveFontSize(0.5),
        alignItems: 'center'
    },
    btnMapaTxt: {
        fontSize: responsiveFontSize(1)
    },
    //address
    txtAddress: {
        width: responsiveFontSize(33),
        fontSize: responsiveFontSize(1.8)
    }
})