import { firebaseDatabase } from '../../firebase';

export function saveOrder(Ref, order, callback){
    firebaseDatabase.ref(Ref).push().set(order)
        .then((value) => {callback(true)})
        .catch((err) => callback(null))
} 

export function saveUser(Ref, id,user, callback){
  firebaseDatabase.ref(Ref).child(id).once('value', dataSnapshot =>{
    if(dataSnapshot.exists()){
      firebaseDatabase.ref(Ref).child(id).update(user)
      .then(()=>{
          callback(true)
      })
      .catch(()=>{
          callback(false)
      })
    }else{
      firebaseDatabase.ref(Ref).child(id).set(user)
      .then(()=>{
          callback(true)
      })
      .catch(()=>{
          callback(false)
      })
    }
})
    
}

export function loadUser(Ref, id, callback){
    firebaseDatabase.ref(Ref).child(id).once('value', dataSnapshot =>{
        if(dataSnapshot.exists()){
            callback(dataSnapshot.val())
        }else{
            callback(null);
        }
    })
}

export function LoadWithKey(Ref,callback){
    firebaseDatabase.ref(Ref).on("value", dataSnapshot=>{
      if(dataSnapshot.exists()){
        let items = [];
        dataSnapshot.forEach(childSnapshot => {
          let item = childSnapshot.val();
          item['key'] = childSnapshot.key;
          items.push(item);
        })
        callback(items);
      }else{
        callback(null)
      }
    })
}

export function LoadDados(Ref,callback){
    firebaseDatabase.ref(Ref).on('value', dataSnapshot =>{
      callback(dataSnapshot.val());
    })
}

export function LoadProduct(Ref, idCategory, callback){
    firebaseDatabase.ref(Ref).orderByChild('idCategory').equalTo(idCategory).on('value', dataSnapshot =>{
        if(dataSnapshot.exists()){
            let items = [];
            dataSnapshot.forEach(childSnapshot => {
              let item = childSnapshot.val();
              item['key'] = childSnapshot.key;
              items.push(item);
            })
            callback(items);
          }else{
            callback(null)
          }
    })
}


