import Constants from 'expo-constants'

export async function sendNotification(title,msg){
    
        await fetch("https://onesignal.com/api/v1/notifications", {
            body: JSON.stringify({
                "app_id": Constants.manifest.extra.oneSignalAppId,
                "contents": {"en": `${msg}`},
                "headings": {"en": `${title}`},
                "included_segments": ["Subscribed Users"],
                "wp_wns_sound": "notification.wav",
                "url": "https://docesensacao.app/Painel/"
            }),
            headers: {
                "authorization": "Basic NjhhNWIzOTQtMjI3Yy00ZjkwLTlhZjItYTQxNjEwOTg5NGY1",
                "content-type": "application/json; charset=utf-8"
            },
            method: "POST"
        }).then((value) => console.log(value))
        .catch((err) => console.log(err))
        
        
    
}