const auth = '0aaa401c17a3c2078c3827c0d2c7efc0'
const TWILIO_ACCOUNT_SID = 'AC4206315a4dbd5ce7f00b2aaf7747def5'

export async function sendZap(to,msg){
    const response = await fetch("https://api.twilio.com/2010-04-01/Accounts/AC21bbb27c30200ca01f4a9c66109f7d07/Messages.json", {
        body: `From=whatsapp%3A%2B14155238886&Body=${msg}&To=whatsapp%3A%2B55${to}`,
        headers: {
          Authorization: "Basic QUMyMWJiYjI3YzMwMjAwY2EwMWY0YTljNjYxMDlmN2QwNzowYWFhNDAxYzE3YTNjMjA3OGMzODI3YzBkMmM3ZWZjMA==",
          "Content-Type": "application/x-www-form-urlencoded" 
        },
        method: "POST"
    })
    const preference = await response.json();
    console.log(preference)
    return preference; 
}