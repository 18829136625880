import React from 'react'
import { View, TouchableOpacity, Text, StyleSheet} from 'react-native'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { responsiveFontSize, responsiveHeight, responsiveWidth } from 'react-native-responsive-dimensions'
import colors from '../styles/colors'

export default function ViewCart({numCart, total, navigation}){

    return numCart > 0 &&(
        <TouchableOpacity style={styles.container} onPress={()=>{navigation.navigate('Cart')}}>
            <View style={{flexDirection: 'row'}}>
                <View>
                    <View style={styles.containerTextNumCart}>
                        <Text style={styles.numCartBtn}>{numCart}</Text>
                    </View>
                    <MaterialCommunityIcons name="cart-outline" size={24} color="black"/>
                </View>
            <Text style={styles.txtCart}>Carrinho</Text>
            </View>
            <Text style={styles.priceCart}>R${total.toFixed(2)}</Text>
        </TouchableOpacity>
    )
}

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        width: '100%',
        height: responsiveHeight(8),
        backgroundColor: 'white',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingHorizontal: responsiveWidth(5)
    },
    containerTextNumCart: {
        backgroundColor: colors.red,
        position: 'absolute',
        width: '60%',
        height: '60%',
        borderRadius: 100,
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 2000,
        left: 15,
        bottom: 15
    }, 
    numCartBtn: {
        color: 'white',
        fontSize: responsiveFontSize(1.2),
        fontWeight: 'bold'
    },
    txtCart: {
        fontSize: responsiveFontSize(1.8),
        marginLeft: responsiveWidth(4)
    },
    priceCart: {
        color: colors.red,
        fontSize: responsiveFontSize(1.9),
        fontWeight: 'bold'
    }
})