import React, { useEffect, useState } from 'react'
import { View, Text, StyleSheet, FlatList, TouchableOpacity, Image } from 'react-native'
import { responsiveFontSize } from 'react-native-responsive-dimensions';
import Header from '../components/header'
import { LoadProduct } from '../services/firebaseServices';
import colors from '../styles/colors';


const pathRef = '/Product'
export default function CategoryDetail({route, navigation}){
    const [business, setBusiness] = useState(null);
    const [category, setCategory] = useState(null);
    const [listItem, setListItem] = useState(null);
    useEffect(()=>{
        const {item, business} = route.params;
        LoadProduct(pathRef, item.key, response => {
            if(response !== null){
                let limpResponse = response.filter(prod => prod.active === true)
                setListItem(limpResponse)
            }
        })
        setCategory(item)
        setBusiness(business)
    },[])
    useEffect(()=>{
        console.log(listItem)
    },[listItem])

    function orderPrice(size){
        const result = size.sort((a,b) => a.price - b.price).filter((value) => value.active === true && value)
        return result[0].price
    }
    function renderItem(item){
        return(
            <TouchableOpacity style={styles.btnItem} onPress={()=>{navigation.navigate('ProductDetail',{category: category, product: item})}}>
                <View>
                    <Text style={styles.itemName}>{item.name}</Text>
                    <Text style={styles.itemDescription}>{item.description}</Text>
                    <Text style={styles.itemPrice}>A partir de R${orderPrice(item.size)}</Text>
                </View>
                {item.img !== null && item.img !== undefined && (
                    <Image style={styles.imgProduct} source={{uri: item.img}}/>
                )}
            </TouchableOpacity>
        )
    }
    return(
        <View style={styles.container}>
            <Header business={business}/>
            <Text style={styles.title}>{category?.name}</Text>
            <FlatList
                data={listItem}
                keyExtractor={(item,index) => index}
                renderItem={({item}) => renderItem(item)}
            />
            
        </View>
    )
}

const styles = StyleSheet.create({
    container:{
        flex: 1,
    },
    title: {
        width: responsiveFontSize(45),
        alignSelf: 'center',
        fontSize: responsiveFontSize(2),
        margin: responsiveFontSize(3)
    },
    btnItem: {
        width: responsiveFontSize(45),
        paddingVertical: responsiveFontSize(2),
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignSelf: 'center',
        borderBottomWidth: responsiveFontSize(0.1),
        borderBottomColor: 'gray'
    },
    itemName: {
        fontWeight: 'bold',
        fontSize: responsiveFontSize(1.8),
        marginBottom: responsiveFontSize(1),
        width: responsiveFontSize(34)
    },
    itemDescription: {
        color: colors.gray2,
        width: responsiveFontSize(34),
        fontSize: responsiveFontSize(1.5),
        marginBottom: responsiveFontSize(2)
    },
    itemPrice: {
        fontSize: responsiveFontSize(1.5)
    },
    imgProduct: {
        backgroundColor: colors.gray,
        width: responsiveFontSize(10),
        height: responsiveFontSize(10),
        borderRadius: responsiveFontSize(0.5)
    }
})