import { StatusBar } from 'expo-status-bar';
import Routes from './src/routes'

import * as serviceWorkerRegistration from "./src/serviceWorkerRegistration";

export default function App() {
  return (
    <>
      <StatusBar style="auto" />
      <Routes/>
    </>
  );
}

serviceWorkerRegistration.register();