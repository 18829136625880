import { MaterialCommunityIcons } from '@expo/vector-icons';
import React from 'react'
import {View, StyleSheet, Modal, Text, ActivityIndicator, TouchableOpacity} from 'react-native'
import { responsiveFontSize } from 'react-native-responsive-dimensions';
import * as Linking from 'expo-linking';

import colors from '../styles/colors';


export default function AlertSocial({visible, txtBtnConfirm, callback}){
    return(
        <View style={styles.centeredView}>
            <Modal
                animationType='fade'
                transparent={true}
                visible={visible}>

                <View style={styles.centeredModal}>
                    <View style={styles.modalView}>
                    {/* <ActivityIndicator size={50} color={colors.primaryDark} /> */}
                    <Text style={styles.title}>Contato</Text>
                    <Text style={styles.msg}>Para mais informações</Text>
                    <View style={{flexDirection: 'row', justifyContent: 'space-around'}}>
                        <MaterialCommunityIcons name="instagram" size={responsiveFontSize(3)} color="#C13584" onPress={()=>{Linking.openURL('https://www.instagram.com/_iago.teles')}} />
                        <MaterialCommunityIcons name="whatsapp" size={responsiveFontSize(3)} color={colors.zapColor} onPress={()=>{Linking.openURL('https://wa.me/5594991817504')}}/>
                    </View>
                    <View style={styles.containerBtn}>
                        <TouchableOpacity style={styles.btnConfirm} onPress={()=>{callback(false)}}>
                            <Text style={styles.btnConfirmTxt}>{txtBtnConfirm}</Text>
                        </TouchableOpacity>
                    </View>
                    </View>
                </View>
            </Modal>
        </View>
    )

}

const styles = StyleSheet.create({
    centeredView: {
      flex: 1
    },
    centeredModal: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: '#000000aa'
    },
    modalView: {
        marginHorizontal: '5%',
        backgroundColor: 'white',
        borderRadius: 5,
        padding: '5%',
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5
    },
    title: {
        color: 'black',
        fontWeight: 'bold',
        fontSize: responsiveFontSize(2),
        marginTop: '3%'
    },
    msg: {
        color: colors.gray2,
        fontSize: responsiveFontSize(1.8),
        marginVertical: '5%'
    },
    containerBtn: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    btnCancel: {
        paddingHorizontal: '5%',
        paddingVertical: '2%',
        marginHorizontal: '2%'
    },
    btnCancelTxt: {
        color: colors.red,
        fontSize: responsiveFontSize(1.8),
        fontWeight: 'bold'
    },
    btnConfirm: {
        paddingHorizontal: '5%',
        paddingVertical: '2%',
        marginHorizontal: '2%'
    },
    btnConfirmTxt: {
        color: colors.zapColor,
        fontSize: responsiveFontSize(1.8),
        fontWeight: 'bold'
    }
});
