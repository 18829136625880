import { MaterialCommunityIcons } from "@expo/vector-icons";
import React, { useEffect, useState } from "react";
import { View, StyleSheet, Text, FlatList, Platform, TouchableOpacity } from 'react-native'
import * as Linking from 'expo-linking';
import { responsiveFontSize } from "react-native-responsive-dimensions";
import Header from "../components/header";
import colors from "../styles/colors";

export default function Info({navigation, route}){
    const[business, setBusiness] = useState(null)
    const[listServices, setListServices] = useState(null)
    useEffect(()=>{
        const {listServices, business} = route.params;
        setBusiness(business)
        setListServices(listServices)
    },[])

    function convertWeekDay(week){
        let day = [
            'Domingo',
            'Segunda-feira',
            'Terça-feira',
            'Quarta-feira',
            'Quinta-feira',
            'Sexta-feira', 
            'Sábado'
        ]
        return day[week];
    }

    function formatTime(item){
        let time = ''
        if(item.to !== '00:00' && item.from !== '00:00' && item.to2 !== '00:00' && item.from2 !== '00:00'){
            time = `${item.from} - ${item.to} e ${item.from2} - ${item.to2}`
        }else{
            time = `${item.from} - ${item.to}`
        }
        return time
    }

    function openMaps(){
        const { lat, long} = business.endereco;
        const scheme = Platform.select({ ios: 'maps:0,0?q=', android: 'geo:0,0?q=', web: 'https://maps.google.com/?q='});
        const latLng = `${lat},${long}`;
        const url = Platform.select({
            ios: `${scheme}${latLng}`,
            android: `${scheme}${latLng}`,
            web: `${scheme}${latLng}`
        });


        Linking.openURL(url); 
    }

    function renderHorario(item){
        return(
            <View style={styles.containerItemHorario}>
                <Text style={styles.subText}>{convertWeekDay(item.week_day)}</Text>
                {item.checked === true ? (
                    <Text style={styles.subText}>{formatTime(item)}</Text>
                ) : (
                    <Text style={styles.closed}>Fechado</Text>
                )}
            </View>
        )
    }

    function renderPayment(item){
        return(
            <View style={{flexDirection: 'row'}}>
                <MaterialCommunityIcons style={{marginRight: responsiveFontSize(1)}} name={item.method === 'Cartão' ? "credit-card-outline" : "cash-usd-outline"} size={responsiveFontSize(2)} color="gray" />
                <Text style={styles.subText}>{item.method}</Text>
            </View>
        )
    }
    return(
        <View style={styles.container}>
            <Header business={business}/>
            <Text style={[styles.title,{marginTop: responsiveFontSize(5)}]}>Horário de functionamento</Text>
            {business !== null && (
                <FlatList
                    data={business.horario}
                    keyExtractor={item => item.week_day}
                    renderItem={({item}) => renderHorario(item)}
                    style={styles.list}
                />
            )}
            <Text style={styles.title}>Tipo de Serviço</Text>
            <Text style={styles.subText}>{listServices}</Text>
            <Text style={styles.title}>Método de pagamento</Text>
            {business !== null && (
                <FlatList
                    data={business.typeOfPayment}
                    keyExtractor={(item,index) => index}
                    renderItem={({item}) => renderPayment(item)}
                    style={styles.listPayment}
                />
            )}
            <Text style={styles.title}>Endereço</Text>
            <View style={styles.horizontal}>
                <View>
                    <Text style={styles.subText}>{`${business?.endereco.rua}, ${business?.endereco.numero}, ${business?.endereco.bairro}`}</Text>
                    <Text style={styles.subText}>{business?.endereco.referencia}</Text>
                </View>
                <TouchableOpacity style={styles.btnMapa} onPress={()=>{openMaps()}}>
                    <MaterialCommunityIcons name="google-maps" size={responsiveFontSize(3)} color={colors.red} />
                    <Text style={styles.btnMapaTxt}>Ver no Mapa</Text>
                </TouchableOpacity>
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        width: responsiveFontSize(49),
        height: '100%',
        backgroundColor: 'white',
        paddingHorizontal: responsiveFontSize(1),
    },
    title: {
        fontSize: responsiveFontSize(1.5),
        fontWeight: 'bold',
        marginVertical: responsiveFontSize(1)
    },
    subText: {
        fontSize: responsiveFontSize(1.5),
        color: colors.gray2
    },
    //array horario
    containerItemHorario: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    list: {
        maxHeight: responsiveFontSize(15)
    },
    closed: {
        color: colors.red,
        fontSize: responsiveFontSize(1.5),
    },
    listPayment: {
        maxHeight: responsiveFontSize(5)
    },
    //btn enderreco
    horizontal: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    btnMapa: {
        paddingHorizontal: responsiveFontSize(0.5),
        alignItems: 'center'
    },
    btnMapaTxt: {
        fontSize: responsiveFontSize(1)
    }
})