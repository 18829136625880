import React, { useEffect, useState } from 'react'
import {View, StyleSheet, Modal, Text, TouchableOpacity} from 'react-native'
import { BarCodeScanner } from 'expo-barcode-scanner';
import { responsiveFontSize } from 'react-native-responsive-dimensions';
import { Camera } from 'expo-camera';

import colors from '../styles/colors';
import AlertModal from './alertModal';


export default function AlertInputMesa({visible, title, msg, txtBtnCancel, txtBtnConfirm, callback}){
    const[numero, setNumero] = useState('')
    const [scanned, setScanned] = useState(false);
    const[alertModal, setAlertModal] = useState(false)

    function handleBarCodeScanned ({ type, data }){
        setScanned(true);
        setAlertModal(true);
        setNumero(data)
    };
    return(
        <View style={styles.centeredView}>
            <Modal
                animationType='fade'
                transparent={true}
                visible={visible}>

                <View style={styles.centeredModal}>
                    <View style={styles.modalView}>
                    {/* <ActivityIndicator size={50} color={colors.primaryDark} /> */}
                    <Text style={styles.title}>{title}</Text>
                    <Text style={styles.msg}>{msg}</Text>
                    <View style={styles.containerCamera}>
                        <Camera
                            style={StyleSheet.absoluteFillObject}
                            barCodeScannerSettings={{
                            barCodeTypes: [BarCodeScanner.Constants.BarCodeType.qr],
                            }}
                            onBarCodeScanned={scanned ? undefined : handleBarCodeScanned}
                        />
                    </View>
                    <View style={styles.containerBtn}>
                        <TouchableOpacity style={styles.btnCancel} onPress={()=>{
                            callback('cancelar')
                            }}>
                            <Text style={styles.btnCancelTxt}>{txtBtnCancel}</Text>
                        </TouchableOpacity>
                    </View>
                    </View>
                </View>
            </Modal>
            <AlertModal
                visible={alertModal}
                title={'Mesa'}
                msg={`O número na sua mesa é: ${numero}.\n\nEstá certo?`}
                txtBtnConfirm='Sim'
                txtBtnCancel={'Não'}
                callback={(value) => {
                    setAlertModal(false)
                    setScanned(false)
                    if(value === 'confirmar'){
                        callback('confirmar',numero);
                    }
                }}
            />
        </View>
    )

}

const styles = StyleSheet.create({
    centeredView: {
      flex: 1
    },
    centeredModal: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: '#000000aa'
    },
    modalView: {
        marginHorizontal: '5%',
        backgroundColor: 'white',
        borderRadius: 5,
        padding: '5%',
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5
    },
    title: {
        color: 'black',
        fontWeight: 'bold',
        fontSize: responsiveFontSize(2),
        marginTop: '3%'
    },
    msg: {
        color: colors.gray2,
        fontSize: responsiveFontSize(1.8),
        marginVertical: '5%'
    },
    containerBtn: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    btnCancel: {
        paddingHorizontal: '5%',
        paddingVertical: '2%',
        marginHorizontal: '2%'
    },
    btnCancelTxt: {
        color: colors.red,
        fontSize: responsiveFontSize(1.8),
        fontWeight: 'bold'
    },
    btnConfirm: {
        paddingHorizontal: '5%',
        paddingVertical: '2%',
        marginHorizontal: '2%'
    },
    btnConfirmTxt: {
        color: colors.zapColor,
        fontSize: responsiveFontSize(1.8),
        fontWeight: 'bold'
    },
    containerCamera: {
        width: responsiveFontSize(20),
        height: responsiveFontSize(20),
        alignSelf: 'center'
    }
});
