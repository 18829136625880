import React, { useCallback, useEffect, useState } from 'react'
import { useFocusEffect } from '@react-navigation/native';
import { View, Text, FlatList, StyleSheet, Alert, TouchableOpacity} from 'react-native'
import { getDataObject, storeDataObject } from '../utils/asyncStorageBase';
import { responsiveFontSize, responsiveHeight, responsiveWidth } from 'react-native-responsive-dimensions';
import colors from '../styles/colors';
import AlertModal from '../components/alertModal';

const largura = responsiveWidth(100);
const _margin = largura <= 500 ? 0 : responsiveWidth(25);
export default function Cart({navigation}){
    const [cart, setCart] = useState([]);
    const [totalCart, setTotalCart] = useState(0)
    const [totalGeral, setTotalGeral] = useState(0)

    const [alertModal, setAlertModal] = useState(false);
    const [selectIndex, setSelectIndex] = useState(0)

    useFocusEffect(
        useCallback(()=>{
            getDataObject('cart').then((value)=> setCart(value));

            return ()=> console.log('saiu')
        },[])
    )

    useEffect(()=>{
        console.log(cart)
        if(cart !== null){
            let tt = 0;
            cart.map(value => tt += value.total);
            setTotalCart(tt);

            let ttG = tt;
            setTotalGeral(ttG)
        }
    },[cart])

    function removeItem(index){
        let cartTemp = cart;
        cartTemp.splice(index,1)
        setCart(cartTemp)
        storeDataObject('cart',cartTemp).then(getDataObject('cart').then((value)=> setCart(value)));
    }

    function renderEmpty(){
        return(
            <View style={styles.containerEmpty}>
                <Text style={styles.txtEmpty}>Não há itens em seu carrinho</Text>
            </View>
        )
    }

    function renderFooter(){
        return cart.length > 0 &&(
            <View style={styles.containerItem}>
                <View style={styles.containerInfoPrice}>
                    <Text style={styles.txtInfo}>SubTotal</Text>
                    <Text style={styles.txtInfo}>R$ {totalCart.toFixed(2)}</Text>
                </View>
                <View style={styles.containerInfoPrice}>
                    <Text style={styles.txtTotal}>Total</Text>
                    <Text style={styles.txtTotal}>R$ {totalGeral.toFixed(2)}</Text>
                </View>
            </View>
        )
    }

    function renderItem(item,index){
        return(
            <View style={styles.containerItem}>
                <Text style={styles.txtProduct}>{item.product}</Text>
                <View style={styles.containerInfo}>
                    <Text style={styles.txtInfo}>1x {item.size.name}{`\n`}</Text>
                    <Text style={styles.txtPriceInfo}>R$ {item.size.price}</Text>
                </View>
                {item.flavor.length > 0 && (
                    <View>
                        <Text style={styles.txtsubTitle}>Sabor</Text>
                        <View style={styles.containerInfo}>
                            <Text style={styles.txtInfo}>{item.flavor.map(value => `1x ${value.name}\n`)}</Text>
                            <Text style={styles.txtPriceInfo}>{item.flavor.map(value => `R$ ${value.price}\n`)}</Text>
                        </View>
                    </View>
                )}
                {item.additional.length > 0 && (
                    <View>
                        <Text style={styles.txtsubTitle}>Adicionais</Text>
                        <View style={styles.containerInfo}>
                            <Text style={styles.txtInfo}>{item.additional.map(value => `1x ${value.name}\n`)}</Text>
                            <Text style={styles.txtPriceInfo}>{item.additional.map(value => `R$ ${value.price}\n`)}</Text>
                        </View>
                    </View>
                )}
                {item.obs !== null && (
                <View>
                    <Text style={styles.txtsubTitle}>Observação</Text>
                    <Text style={styles.txtInfo}>{item.obs}</Text>
                </View>
                )}
                <TouchableOpacity onPress={()=>{
                        setSelectIndex(index)
                        setAlertModal(!alertModal);
                        
                    }}>
                    <Text style={styles.remove}>Remover</Text>
                </TouchableOpacity>
            </View>
        )
    }
    return(
        <View style={styles.container}>
            <FlatList
                data={cart}
                keyExtractor={(item,index) => index}
                renderItem={({item,index}) => renderItem(item,index)}
                ListEmptyComponent={renderEmpty()}
                ListFooterComponent={renderFooter()}
            />
            {cart.length > 0 &&(
                <TouchableOpacity style={styles.btnNext} onPress={()=>{navigation.navigate('CartDetail',{subTotal: totalCart, total: totalGeral, cart: cart})}}>
                    <Text style={styles.btnNextTxt}>Próximo</Text>
                </TouchableOpacity>
            )}
            <AlertModal visible={alertModal} 
                title='Remover item do carrinho' 
                msg='Tem certeza que deseja remover este item do carrinho?'
                txtBtnCancel="Não"
                txtBtnConfirm="Sim"
                callback={(value) => {
                    if(value === 'confirmar'){
                        removeItem(selectIndex); 
                        setAlertModal(!alertModal)
                    }else{
                        setAlertModal(!alertModal)
                    } 
                }}/>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        marginHorizontal: _margin
    },
    containerEmpty: {
        height: responsiveHeight(95),
        backgroundColor: 'white',
        justifyContent: 'center',
        alignItems: 'center'
    },
    containerItem: {
        padding: responsiveWidth(5),
        backgroundColor: 'white'
    },
    containerInfo: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    containerInfoPrice: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end'
    },
    txtProduct: {
        fontSize: responsiveFontSize(2)
    },
    txtsubTitle: {
        fontSize: responsiveFontSize(1.8)
    },
    txtInfo: {
        fontSize: responsiveFontSize(1.6),
        color: colors.gray2,
        marginTop: responsiveWidth(2),
        marginHorizontal: responsiveWidth(3)
    },
    txtPriceInfo: {
        fontSize: responsiveFontSize(1.6)
    },
    txtTotal: {
        fontSize: responsiveFontSize(2),
        fontWeight: 'bold',
        marginTop: responsiveWidth(2),
        marginHorizontal: responsiveWidth(3),
        color: 'black'
    },
    remove: {
        color: colors.red,
        marginTop: responsiveWidth(3),
        fontSize: responsiveFontSize(1.8)
    },
    txtEmpty: {
        color: colors.red,
        fontSize: responsiveFontSize(2),
        fontWeight: 'bold'
    },
    btnNext: {
        width: '90%',
        backgroundColor: colors.red,
        borderRadius: 5,
        alignSelf: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '5%',
        marginBottom: '5%'
    },
    btnNextTxt: {
        color: 'white',
        fontSize: responsiveFontSize(2),
        fontWeight: 'bold'
    }
})