const colors = {
    primary: '#595959',
    primaryDark: '#BF4158',//bacground
    secundaryDark: '#FFFFFF',//barra de status
    btn: '#BF4158',
    bk: '#FEF9F3',
    txtTitulo: 'white',
    gray: '#dfdfdf',
    gray2: '#595959',
    gray3: '#b9b5b1',
    red: '#ff2800',
    redBack: '#cc2000',
    green: '#0BD93E',
    yellow: '#ffb300',
    btnFace: '#0f81e7',
    zapColor: '#25D366',
    purple: '#836FFF',
    black: '#302d2d',
    transparent: '#282828'

}


export default colors;