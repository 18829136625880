import firebase from 'firebase/compat/app';
import 'firebase/compat/database'
import 'firebase/compat/analytics'
import 'firebase/compat/auth'

// export const firebaseConfig = {
//     apiKey: "AIzaSyDDTqeqCScbi81q9YPvyr10zoGcTWt-7RY",
//     authDomain: "bot-delivery-zap.firebaseapp.com",
//     projectId: "bot-delivery-zap",
//     storageBucket: "bot-delivery-zap.appspot.com",
//     messagingSenderId: "189601271388",
//     appId: "1:189601271388:web:f624f066bd78de75762099",
//     measurementId: "G-Y63XSDRQMZ"
// };

//doce sensaçao
export const firebaseConfig = {
  apiKey: "AIzaSyCmQnkYUyyzWAjTiJ75WSGHJs0mhXA1ScQ",
  authDomain: "sexdocesensacao.firebaseapp.com",
  databaseURL: "https://sexdocesensacao-default-rtdb.firebaseio.com",
  projectId: "sexdocesensacao",
  storageBucket: "sexdocesensacao.appspot.com",
  messagingSenderId: "387407563496",
  appId: "1:387407563496:web:d12f2a79973b0e87b6975d",
  measurementId: "G-W7XBGK7M0B"
}; 


export const firebaseImpl = firebase.initializeApp(firebaseConfig);
export const firebaseAuth = firebase.auth();
export const firebaseAnalytics = firebase.analytics(firebaseImpl);
export const firebaseDatabase = firebase.database();
export default firebase;