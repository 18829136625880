import React, { useEffect, useState } from "react";
import { View, Text, StyleSheet, TouchableOpacity, Alert} from 'react-native'
import { Input } from "react-native-elements";
import { responsiveFontSize, responsiveWidth } from "react-native-responsive-dimensions";
import { firebaseAuth } from "../../firebase";
import AlertModal from "../components/alertModal";
import LoadingModal from "../components/loadingModal";
import { saveUser } from "../services/firebaseServices";
import colors from "../styles/colors";
import { getDataObject, storeDataObject } from "../utils/asyncStorageBase";
import { formatNumberPhone } from "../utils/formatNumberPhone";

export default function User({navigation}){
    const[user, setUser] = useState(null)
    const[email, setEmail] = useState('')
    const[phone, setPhone] = useState('')
    const[name, setName] = useState('')
    const[password, setPassword] = useState('')
    const[confirmPass, setConfirmPass] = useState('')

    //alert
    const[alertModal, setAlertModal] = useState(false)
    const[loadingModal, setLoadingModal] = useState(false)
    const [alertSucess, setAlertSucess] = useState(false)

    const[title, setTitle] = useState('')
    const[msg, setMsg] = useState('')

    useEffect(()=>{
        getDataObject('user').then((value) => value !== null && (setUser(value)))
    },[])

    useEffect(()=>{
        if(user !== null){
            setEmail(user.email);
            setName(user.name);
            setPhone(user.phone);
        }
    },[user])

    function save(){
        if(name !== '' && phone !== '' && email !== '' && password !== '' && confirmPass !== ''){
            setLoadingModal(true)
            let p = phone.replace(/[^\d]/g,"");
            let user = {
                name,
                phone: p,
                email
            }

            if(password === confirmPass){
                if(password.length < 6){
                    setLoadingModal(false)
                    setTitle('Ops 😬')
                    setMsg('As senhas informadas devem ter no minimo 6 caracteres, altere e tente novamente. 😉')
                    setAlertModal(true)
                }else{ 
                    firebaseAuth.createUserWithEmailAndPassword(email,password)
                    .then((userCredential)=>{
                        userCredential.user.sendEmailVerification()
                        setLoadingModal(false)
                        saveUser('User', userCredential.user.uid,user, response => setAlertSucess(response))
                        
                    })
                    .catch((error)=>{
                        console.log(`${error.message}\n${error.code}`)
                        if(error.code === 'auth/wrong-password' || error.code === 'auth/user-not-found'){
                            setLoadingModal(false)
                            setTitle('Essa não 🤔')
                            setMsg(`Login ou senha incorretos!\nTente novamente.`)
                            setAlertModal(true)
                        }
                    })
                }

            }else{
                setLoadingModal(false)
                setTitle('Ops 😬')
                setMsg('As senhas informadas não batem, verifique se informou a mesma senha e tente novamente. 😉')
                setAlertModal(true)
            }

            
        }else{
            setTitle('Ah não 🤔')
            setMsg('Verifique se você preencheu todos os campos e tente novamente. 😉')
            setAlertModal(!alertModal)
        }
    }
    return(
        <View style={styles.container}>
            <Text style={styles.txtTitle}>Vamos começar 😍</Text>
            <Text style={styles.txtSubTitle}>Informe suas informações abaixo para começar.</Text>
            <Input 
                containerStyle={styles.inputName}
                label='Nome:'
                placeholder='Digite seu nome completo'
                keyboardType={'email-address'}
                placeholderTextColor={colors.gray}
                onChangeText={setName}
                value={name}/>
            <Input 
                containerStyle={styles.inputName}
                label='Telefone:'
                placeholder='Digite seu número'
                keyboardType={'phone-pad'}
                maxLength={15}
                placeholderTextColor={colors.gray}
                onChangeText={setPhone}
                value={formatNumberPhone(phone)}/>
            <Input 
                containerStyle={styles.inputName}
                label='E-mail:'
                placeholder='Digite seu e-mail'
                keyboardType={'email-address'}
                placeholderTextColor={colors.gray}
                onChangeText={setEmail}
                value={email}/>
            <Input 
                containerStyle={styles.inputName}
                label='Senha:'
                placeholder='Digite sua senha'
                keyboardType={'default'}
                placeholderTextColor={colors.gray}
                onChangeText={setPassword}
                secureTextEntry={true}
                value={password}/>
            <Input 
                containerStyle={styles.inputName}
                label='Confirmar senha:'
                placeholder='Digite a mesma senha'
                keyboardType={'default'}
                placeholderTextColor={colors.gray}
                onChangeText={setConfirmPass}
                secureTextEntry={true}
                value={confirmPass}/>
            <TouchableOpacity style={styles.btn} onPress={()=>{save()}}>
                <Text style={styles.btnTxt}>Confirmar</Text>
            </TouchableOpacity>
            <AlertModal visible={alertModal}
                title={title}
                msg={msg}
                txtBtnCancel={''}
                txtBtnConfirm={'Ok'}
                callback={(value) =>{
                    setAlertModal(false)
                }}
            
            />
            <AlertModal
                visible={alertSucess}
                title={'Login 📲'}
                msg={'Criado com sucesso, verifique seu e-mail para confirmar seu email ✌🏻'}
                txtBtnConfirm={'Ok'}
                callback={(value) =>{
                    setAlertSucess(false)
                    if(value === 'confirmar'){
                        navigation.goBack()
                    }
                }}
            />
            <LoadingModal visible={loadingModal}
                msg='Aguarde...'
            />
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        backgroundColor: 'white',
        paddingHorizontal: responsiveWidth(5)
    },
    txtTitle: {
        fontSize: responsiveFontSize(4),
        marginTop: responsiveWidth(5)
    },
    txtSubTitle: {
        fontSize: responsiveFontSize(1.8),
        color: colors.gray2,
        marginTop: responsiveWidth(2),
        marginBottom: responsiveWidth(5)
    },
    btn: {
        width: responsiveWidth(90),
        height: responsiveWidth(12),
        backgroundColor: colors.red,
        borderRadius: 5,
        alignSelf: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        paddingHorizontal: responsiveWidth(5),
        marginVertical: responsiveWidth(5)
    },
    btnTxt: {
        color: 'white',
        fontSize: responsiveFontSize(1.8),
        fontWeight: 'bold'
    }
})