import React, { useState } from "react";
import { View, Text, StyleSheet, TouchableOpacity, Alert} from 'react-native'
import { Input } from "react-native-elements";
import { responsiveFontSize, responsiveWidth } from "react-native-responsive-dimensions";
import { firebaseAuth } from "../../firebase";
import AlertModal from "../components/alertModal";
import LoadingModal from "../components/loadingModal";
import { loadUser } from "../services/firebaseServices";
import colors from "../styles/colors";
import { storeDataObject } from "../utils/asyncStorageBase";
import { formatNumberPhone } from "../utils/formatNumberPhone";

export default function Login({navigation}){
    const[email, setEmail] = useState('')
    const[pass, setPass] = useState('')

    const[alertModal, setAlertModal] = useState(false)
    const[title, setTitle] = useState('')
    const[msg, setMsg] = useState('')
    const[loadingModal, setLoadingModal] = useState(false)

    function recuperarSenha(){
        console.log("Email ",email)
        if(email !== '' && email !== null && email !== undefined){
            firebaseAuth.sendPasswordResetEmail(email)
            .then(()=>{
                setSucess(true)
                setTitle('Sucesso 👍🏻')
                setMsg(`Email de recuperação foi enviado para ${email}`)
                setAlertModal(true)
            })
            .catch((error) => {
                console.log(error.code)
                if(error.code === 'auth/user-not-found'){
                    setTitle('Oh não 😬')
                    setMsg('Email informado não pertence a nenhum usuário, tente novamente. ✌🏻')
                    setAlertModal(true)
                }else if(error.code !== undefined){
                    setTitle('Oh não 😬')
                    setMsg('Email inválido, tente novamente. ✌🏻')
                    setAlertModal(true)

                }
            })
        }else{
            setTitle('Oh não 😬')
            setMsg('Verifique se informou o seu email acima, e tente novamente 😉')
            setAlertModal(true)
        }
    }
    function checkLogin(){
        setLoadingModal(true);
        if(email !== '' && pass !== ''){
            firebaseAuth.signInWithEmailAndPassword(email,pass)
                .then(()=>{
                    navigation.goBack()
                    
                })
                .catch((error)=>{
                    console.log(`${error.message}\n${error.code}`)
                    if(error.code === 'auth/wrong-password' || error.code === 'auth/user-not-found'){
                        setLoadingModal(false)
                        setTitle('Essa não 🤔')
                        setMsg(`Login ou senha incorretos!\nTente novamente.`)
                        setAlertModal(true)
                    }
                })
        }else{
            setLoadingModal(false)
            setTitle('Ops 🤔')
            setMsg('Preencha todos os campos para continuar. ✌🏻')
            setAlertModal(true)
        }
    }
    return(
        <View style={styles.container}>
            <Text style={styles.txtTitle}>Vamos começar 😍</Text>
            <Text style={styles.txtSubTitle}>Informe seu email e senha usado na hora de criar sua conta. 😉</Text>
            <Input 
                containerStyle={styles.inputName}
                label='E-mail:'
                placeholder='Digite seu e-mail'
                keyboardType={'email-address'}
                placeholderTextColor={colors.gray}
                onChangeText={setEmail}
                value={email}/>
            <Input 
                containerStyle={styles.inputName}
                label='Senha:'
                placeholder='Digite sua senha'
                keyboardType={'default'}
                placeholderTextColor={colors.gray}
                onChangeText={setPass}
                secureTextEntry={true}
                value={pass}/>

            <TouchableOpacity style={styles.btnRecoveryPass} onPress={()=>{recuperarSenha()}}>
                <Text style={styles.pass}>Esqueceu a senha?</Text>
            </TouchableOpacity>

            <TouchableOpacity style={styles.btn} onPress={()=>{checkLogin()}}>
                <Text style={styles.btnTxt}>Confirmar</Text>
            </TouchableOpacity>
            <AlertModal visible={alertModal}
                title={title}
                msg={msg}
                txtBtnCancel={''}
                txtBtnConfirm={'Ok'}
                callback={(value) =>{
                    setAlertModal(false)
                }}
            
            />
            <LoadingModal visible={loadingModal}
                msg='Aguarde...'
            />
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        backgroundColor: 'white',
        paddingHorizontal: responsiveWidth(5)
    },
    txtTitle: {
        fontSize: responsiveFontSize(4),
        marginTop: responsiveWidth(5)
    },
    txtSubTitle: {
        fontSize: responsiveFontSize(1.8),
        color: colors.gray2,
        marginTop: responsiveWidth(2),
        marginBottom: responsiveWidth(5)
    },
    btn: {
        width: responsiveWidth(90),
        height: responsiveWidth(12),
        backgroundColor: colors.red,
        borderRadius: 5,
        alignSelf: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        paddingHorizontal: responsiveWidth(5),
        marginVertical: responsiveWidth(5)
    },
    btnTxt: {
        color: 'white',
        fontSize: responsiveFontSize(1.8),
        fontWeight: 'bold'
    }
})