import React, { useState } from "react";
import { View, Text, TouchableOpacity} from 'react-native'
import { responsiveFontSize } from "react-native-responsive-dimensions";
import AlertSocial from "./alertSocial";

export default function Develop(){
    const[alertModal, setAlertModal] = useState(false)

    return(
        <View>
            <TouchableOpacity style={{
                flexDirection: 'row', 
                justifyContent: 'flex-end', 
                alignItems: 'center',
                margin: responsiveFontSize(1)
            }} onPress={()=>{setAlertModal(true)}}>
                <Text style={{
                    fontSize: responsiveFontSize(0.8), 
                    marginHorizontal: responsiveFontSize(0.5)
                }}>Desenvolvido por:</Text>
                <Text style={{
                    fontSize: responsiveFontSize(1),
                    fontStyle: 'italic'
                }}>Iago Teles</Text>
            </TouchableOpacity>
                <AlertSocial 
                    visible={alertModal}
                    txtBtnConfirm={'Ok'}
                    callback={(value) =>{
                        setAlertModal(value)
                    }}
                />
        </View>
    )
}