import React, { useCallback, useEffect, useState } from 'react';
import { View, Text, StyleSheet, TouchableOpacity, FlatList} from 'react-native';
import { responsiveFontSize, responsiveHeight, responsiveScreenWidth, responsiveWidth } from 'react-native-responsive-dimensions';
import { useFocusEffect } from '@react-navigation/native';
import { MaterialCommunityIcons  } from '@expo/vector-icons';
import { format } from 'date-fns'
import ptBR from 'date-fns/locale/pt-BR'

import Header from '../components/header';
import { getDataObject } from '../utils/asyncStorageBase';
import ViewCart from '../components/viewCart';
import { LoadDados, LoadWithKey } from '../services/firebaseServices';
import colors from '../styles/colors';
import AlertModal from '../components/alertModal';
import { sendNotification } from '../utils/apiSendNotification';
import Develop from '../components/develop';



const pathRef = '/Category'
const pathRefBusiness = '/Business/Dados'
export default function Home({navigation}) {
    const [business, setBusiness] = useState(null)
    const [category, setCategory] = useState(null)
    const [cart, setCart] = useState([]);
    const [totalCart, setTotalCart] = useState(0)

    //verificar pagamento
    const[cartCredit, setCartCredit] = useState(false)
    const[cartMoney, setCartMoney] = useState(false)

    const [listServices, setListServices] = useState('')

    //alertModal 
    const[open, setOpen] = useState(true);
    const[alertModal, setAlertModal] = useState(false)
    useFocusEffect(
        useCallback(()=>{ 
            getDataObject('cart').then((value)=> setCart(value));

            return ()=> console.log('saiu')
        },[])
    )

    useEffect(()=>{
        LoadWithKey(pathRef, response => {
            if(response !== null && response !== undefined){
                let limp = response.sort((a,b)=>{
                    if (a.ordem > b.ordem) {
                        return 1;
                      }
                      if (a.ordem < b.ordem) {
                        return -1;
                      }
                      // a must be equal to b
                      return 0;
                })
                setCategory(limp)
            }
        })
        LoadDados(pathRefBusiness, response => setBusiness(response))

    },[])

    useEffect(()=>{
        if(business !== null){
            checkHorario()
            let typeOfService = business.typeOfService.filter(item => item.checked).map(item => item.method);
            let services = '';
            switch(typeOfService.length){
                case 2:
                    services = `${typeOfService[0]} e ${typeOfService[1]}`
                    break;
                case 3:
                    services = `${typeOfService[0]}, ${typeOfService[1]} e ${typeOfService[2]}`
                    break;
                default:
                    services = `${typeOfService}`
                    break;
            }
            setListServices(services);

            business.typeOfPayment.map(item => {
                console.log(item)
                if(item.method === 'Dinheiro' && item.checked === true){
                    setCartMoney(true)
                }else if(item.method === 'Cartão' && item.checked === true){
                    setCartCredit(true)
                }else{
                    setCartCredit(false)
                    setCartMoney(false)
                }
            })
        }
    },[business])

    useEffect(()=>{
        if(cart !== null){
            let tt = 0;
            cart.map(value => tt += value.total);
            setTotalCart(tt);
            
        }
    },[cart])

    function renderHeader(){
        return(
            <View style={styles.containerInfo}>
                <View style={styles.horizontal}>
                    <MaterialCommunityIcons name="clock-time-four-outline" size={responsiveFontSize(3)} color="gray" />
                    <Text style={styles.txtInfo}>{business?.deliveryTime} min</Text>
                    {cartCredit && (
                        <MaterialCommunityIcons name="credit-card-outline" size={responsiveFontSize(3)} color="gray" />
                    )}
                    {cartMoney && (
                        <MaterialCommunityIcons name="cash-usd-outline" size={responsiveFontSize(3)} color="gray" />
                    )}
                    {open === false && (<Text style={styles.closed}>Fechado</Text>)}
                </View>
                <View style={styles.horizontal}>
                    <MaterialCommunityIcons name="moped" size={responsiveFontSize(3)} color="gray" />
                    <Text style={styles.txtInfo}>R$ {business?.taxaEntrega}</Text>
                </View>
                <View style={styles.horizontal}>
                    <MaterialCommunityIcons name="room-service-outline" size={responsiveFontSize(3)} color="gray" />
                    <Text style={styles.txtInfo}>{listServices}</Text>
                </View>
                <TouchableOpacity style={styles.btnInfo} onPress={()=>{navigation.navigate('Info',{business, listServices})}}>
                    <MaterialCommunityIcons name="information-outline" size={responsiveFontSize(2)} color={colors.red}/>
                </TouchableOpacity>
            </View>
        )
    }

    function renderItem(item){
        return(
            <TouchableOpacity style={styles.btnCategory} 
                onPress={()=>{
                    open === true ?
                    navigation.navigate('CategoryDetail',{item: item, business}) :
                    setAlertModal(true)
                }}>
                <Text style={styles.txtCategory}>{item.name}</Text>
                <MaterialCommunityIcons name="arrow-right" size={24} color="gray" />
            </TouchableOpacity>
        )
    }

    function checkHorario(){
        const {horario} = business;
        let dataNow = new Date()

        //setar horas de acordo com o dia da semana de hoje
        let week = dataNow.getDay()
        
        //ver no primeiro tempo
        let timeNow = format(dataNow, 'HH:mm',{locale: ptBR})
        if(horario[week].checked === true){
            if(horario[week].to !== '00:00' && horario[week].from !== '00:00' && horario[week].to2 !== '00:00' && horario[week].from2 !== '00:00'){
                if(timeNow >= horario[week].from && timeNow <= horario[week].to || timeNow >= horario[week].from2 && timeNow <= horario[week].to2){
                    setOpen(true)
                }else{
                    setOpen(false)
                }
    
            }else{
                if(timeNow >= horario[week].from && timeNow <= horario[week].to){
                    setOpen(true)
                }else{
                    setOpen(false)
                }
            }

        }else{
            setOpen(false)
        }

        
        


    }

    return(
        <View style={styles.container}>
            <Header business={business}/>          
            <FlatList
                data={category}
                keyExtractor={item => item.key}
                renderItem={({item}) => renderItem(item)}
                ListHeaderComponent={renderHeader()}
            />
            <AlertModal
                visible={alertModal}
                title={'Ah não 😔'}
                msg={'Infelizmente estamos fechados, tente novamente mais tarde ou olhe os nossos horário para ver quando iremos retornar. ⏰'}
                txtBtnCancel={'Não, obrigado'}
                txtBtnConfirm={'Ver horários'}
                callback={(value) => {
                    setAlertModal(false)
                    if(value === 'confirmar'){
                        navigation.navigate('Info',{business, listServices})
                    }
                }}
            />
            <Develop/>
            {cart !== null && open === true && (
                <ViewCart numCart={cart?.length} total={totalCart} navigation={navigation}/>
            )}
        </View>
    )
}

const styles = StyleSheet.create({ 
    container:{
        flex: 1,
    },
    horizontal: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingHorizontal: responsiveFontSize(3)
    },
    containerInfo: {
        width: responsiveFontSize(49),
        backgroundColor: '#fff',
        paddingTop: responsiveFontSize(3),
        marginBottom: responsiveFontSize(3),
        alignSelf: 'center'
    },
    txtInfo: {
        color: 'gray',
        marginHorizontal: responsiveFontSize(2),
        fontSize: responsiveFontSize(1.6)
    },
    btnCategory: {
        backgroundColor: '#fff',
        flexDirection: 'row',
        width: responsiveFontSize(44),
        height: responsiveFontSize(8),
        borderRadius: 5,
        alignSelf: 'center',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingHorizontal: responsiveFontSize(3),
        marginBottom: responsiveFontSize(1)
    },
    txtCategory: {
        fontSize: responsiveFontSize(1.8),
        fontWeight: 'bold'
    },
    //infor
    btnInfo: {
        alignSelf: 'flex-end',
        paddingHorizontal: responsiveFontSize(2),
        paddingVertical: responsiveFontSize(0.5),
        marginRight: responsiveFontSize(3),
        borderRadius: responsiveFontSize(0.5),
        borderWidth: responsiveFontSize(0.1),
        borderColor: colors.red,
        marginBottom: responsiveFontSize(2)
    },
    closed: {
        color: 'white',
        fontWeight: 'bold',
        fontSize: responsiveFontSize(1.6),
        marginLeft: 'auto',
        backgroundColor: colors.gray,
        paddingHorizontal: responsiveFontSize(1),
        paddingVertical: responsiveFontSize(0.5),
        borderRadius: responsiveFontSize(0.5)
    }
})

