import React, { useEffect, useState } from "react";
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native'
import { responsiveFontSize, responsiveWidth } from "react-native-responsive-dimensions";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import * as Linking from 'expo-linking';

import { sendZap } from "../services/sendMsgZap";
import colors from "../styles/colors";
import { sendNotification } from "../utils/apiSendNotification";

const phone = '+14155238886'
export default function SucessOrder({ navigation, route }) {
    const [id, setId] = useState(null)
    useEffect(() => {
        const { id, user, order } = route.params;
        setId(id)
        let _userPhoneZap = user.phone.slice(0, 2) + user.phone.slice(3)
        //send msg info pedido

        let msg =
            `*Pedido nº ${id}*\n
*Modo de entrega:* ${order.deliveryMode}
Endereço: ${order.address.rua}, nº ${order.address.numero} - ${order.address.bairro}
${order.address.referencia}

*Itens:*
    ${order.cart.map(item =>`
    *${item.product}*
        ${item.flavor !== undefined ? `
        Sabor:
            ${item.flavor.map(item => `1x ${item.name}\n            `).join('')}`:''}
        ${item.size !== undefined ? 
        `Tamanho:
            1x ${item.size.name}`:''}
        ${item.additional.length > 0 ? 
        `Adicionais:
            ${item.additional.map(item => ` 1x ${item.name}\n            `).join('')}`:''}  
        ${item.obs !== null && item.obs !== undefined && item.obs !== ''?
        `Obs: 
            ${item.obs}`:''}`).join('')}

*Forma de Pagamento:* ${order.paymentMode}
*Total: R$${order.total.toFixed(2)}*
`
        sendNotification('Novo Pedido 🔔',`${user.name} fez um novo pedido. ✅`);
        sendZap(_userPhoneZap, msg)
        sendZap(_userPhoneZap, `Olá ${user.name}, seu pedido foi realizado com sucesso. Vou te atualizando sobre seu pedido por aqui 😉.`)


    }, [])
    return (
        <View style={styles.container}>
            <Text style={styles.emoji}>👍🏻</Text>
            <Text style={styles.title}>Pedido Realizado com Sucesso ✅</Text>
            <Text style={styles.subTxt}>Você receberá atualizações através do Whatsapp.</Text>

            <TouchableOpacity style={styles.btnZap} onPress={() => { Linking.openURL(`https://wa.me/${phone}`) }}>
                <MaterialCommunityIcons name="whatsapp" size={24} color="white" />
                <Text style={styles.btnTxt}>Continuar no Whatsapp</Text>
            </TouchableOpacity>
            <TouchableOpacity style={styles.btnHome} onPress={() => { navigation.navigate('Home') }}>
                <MaterialCommunityIcons name="home-circle-outline" size={24} color="white" />
                <Text style={styles.btnTxt}>Voltar para o início</Text>
            </TouchableOpacity>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center'
    },
    emoji: {
        fontSize: responsiveFontSize(15)
    },
    title: {
        fontSize: responsiveFontSize(2),
        marginTop: responsiveWidth(10)
    },
    subTxt: {
        marginVertical: responsiveWidth(2),
        fontSize: responsiveFontSize(1.8),
        color: colors.gray2
    },
    btnZap: {
        flexDirection: 'row',
        width: responsiveWidth(90),
        height: responsiveWidth(15),
        backgroundColor: colors.zapColor,
        borderRadius: 5,
        justifyContent: 'center',
        alignItems: 'center',
        marginVertical: responsiveWidth(5)
    },
    btnHome: {
        flexDirection: 'row',
        width: responsiveWidth(90),
        height: responsiveWidth(15),
        backgroundColor: colors.red,
        borderRadius: 5,
        justifyContent: 'center',
        alignItems: 'center',
    },
    btnTxt: {
        color: 'white',
        fontSize: responsiveFontSize(1.8),
        fontWeight: 'bold',
        marginHorizontal: responsiveWidth(5)
    },
})