import React from 'react';

import { NavigationContainer } from '@react-navigation/native';
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { AntDesign, Feather, Entypo, FontAwesome  } from '@expo/vector-icons';
import * as Linking from 'expo-linking';

import Home from './pages/home';
import CategoryDetail from './pages/categoryDetail';
import ProductDetail from './pages/productDetail';
import Cart from './pages/cart';
import colors from './styles/colors';
import CartDetail from './pages/cartDetail';
import SucessOrder from './pages/sucessOrder';
import User from './pages/user';
import Login from './pages/login';
import AddressUser from './pages/addressUser';
import Info from './pages/info';

const prefix = Linking.createURL("/");
const config = {
  screens: {
    initialRouteName: 'Home',
    Home: "",
    SucessOrder: 'SucessOrder/:id/:user/:order',
    Login: "Login"
  }
};
const linking = {
   prefixes: [prefix],
   config
};

const Stack = createNativeStackNavigator();

export default function App() {
    return (
      <NavigationContainer  linking={linking}>
        <Stack.Navigator>
          <Stack.Screen name="Home" component={Home} options={{headerShown: false}}/>
          <Stack.Screen name="CategoryDetail" component={CategoryDetail} 
            options={({navigation}) =>({
              title: '',
              headerTintColor: '#FFF',
              headerTitleAlign: 'center',
              headerLeft: () =>(
                <Entypo style={{marginLeft: 10}} name= 'chevron-left' size={24} color={colors.red} onPress={()=>{navigation.navigate('Home')}}/>
              )
            })}/>
          <Stack.Screen name="ProductDetail" component={ProductDetail} 
            options={({navigation}) =>({
              title: '',
              headerLeft: () =>(
                <Entypo style={{marginLeft: 10}} name= 'chevron-left' size={24} color={colors.red} onPress={()=>{navigation.navigate('CategoryDetail')}}/>
              )
            })}/>
          <Stack.Screen name="Cart" component={Cart} 
            options={({navigation}) =>({
              title: 'Carrinho',
              headerTitleAlign: 'center',
              headerLeft: () =>(
                <Entypo style={{marginLeft: 10}} name= 'chevron-left' size={24} color={colors.red} onPress={()=>{navigation.navigate('Home')}}/>
              )
            })}/>
          <Stack.Screen name="CartDetail" component={CartDetail} 
            options={({navigation}) =>({
              title: 'Finalizando',
              headerTitleAlign: 'center',
              headerLeft: () =>(
                <Entypo style={{marginLeft: 10}} name= 'chevron-left' size={24} color={colors.red} onPress={()=>{navigation.navigate('Cart')}}/>
              )
            })}/>
          <Stack.Screen name="SucessOrder" component={SucessOrder} 
            options={({navigation}) =>({
              headerShown: false
            })}/>
          <Stack.Screen name="User" component={User} 
            options={({navigation}) =>({
              title: '',
              headerTitleAlign: 'center',
              headerLeft: () =>(
                <Entypo style={{marginLeft: 10}} name= 'chevron-left' size={24} color={colors.red} onPress={()=>{navigation.goBack()}}/>
              )
            })}/>
          <Stack.Screen name="Login" component={Login} 
            options={({navigation}) =>({
              title: '',
              headerTitleAlign: 'center',
              headerLeft: () =>(
                <Entypo style={{marginLeft: 10}} name= 'chevron-left' size={24} color={colors.red} onPress={()=>{navigation.goBack()}}/>
              )
            })}/>
          <Stack.Screen name="AddressUser" component={AddressUser} 
            options={({navigation}) =>({
              title: 'Endereço',
              headerTitleAlign: 'center',
              headerLeft: () =>(
                <Entypo style={{marginLeft: 10}} name= 'chevron-left' size={24} color={colors.red} onPress={()=>{navigation.goBack()}}/>
              )
            })}/>
          <Stack.Screen name="Info" component={Info} 
            options={({navigation}) =>({
              title: '',
              headerTitleAlign: 'center',
              headerLeft: () =>(
                <Entypo style={{marginLeft: 10}} name= 'chevron-left' size={24} color={colors.red} onPress={()=>{navigation.goBack()}}/>
              )
            })}
          />
        </Stack.Navigator>
      </NavigationContainer>
    );
  }