import React, { useState } from 'react'
import {View, StyleSheet, Modal, Text, ActivityIndicator, TouchableOpacity} from 'react-native'
import { Input } from 'react-native-elements';
import { responsiveFontSize } from 'react-native-responsive-dimensions';
import colors from '../styles/colors';
import { formatPriceInput } from '../utils/formatPriceInput';


export default function AlertInputModal({visible, title, msg, txtBtnCancel, txtBtnConfirm, callback, responseInput}){
    const[numero, setNumero] = useState('')
    return(
        <View style={styles.centeredView}>
            <Modal
                animationType='fade'
                transparent={true}
                visible={visible}>

                <View style={styles.centeredModal}>
                    <View style={styles.modalView}>
                    {/* <ActivityIndicator size={50} color={colors.primaryDark} /> */}
                    <Text style={styles.title}>{title}</Text>
                    <Text style={styles.msg}>{msg}</Text>
                    <Input 
                        label=''
                        placeholder='50,00'
                        keyboardType={'phone-pad'}
                        maxLength={7}
                        placeholderTextColor={colors.gray}
                        onChangeText={text =>  setNumero(formatPriceInput(text))}
                        value={numero}/>
                    <View style={styles.containerBtn}>
                        <TouchableOpacity style={styles.btnCancel} onPress={()=>{
                            callback('cancelar')
                            responseInput(numero)
                            }}>
                            <Text style={styles.btnCancelTxt}>{txtBtnCancel}</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={styles.btnConfirm} onPress={()=>{
                            callback('confirmar')
                            responseInput(numero)
                            }}>
                            <Text style={styles.btnConfirmTxt}>{txtBtnConfirm}</Text>
                        </TouchableOpacity>
                    </View>
                    </View>
                </View>
            </Modal>
        </View>
    )

}

const styles = StyleSheet.create({
    centeredView: {
      flex: 1
    },
    centeredModal: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: '#000000aa'
    },
    modalView: {
        marginHorizontal: '5%',
        backgroundColor: 'white',
        borderRadius: 5,
        padding: '5%',
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5
    },
    title: {
        color: 'black',
        fontWeight: 'bold',
        fontSize: responsiveFontSize(2),
        marginTop: '3%'
    },
    msg: {
        color: colors.gray2,
        fontSize: responsiveFontSize(1.8),
        marginVertical: '5%'
    },
    containerBtn: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    btnCancel: {
        paddingHorizontal: '5%',
        paddingVertical: '2%',
        marginHorizontal: '2%'
    },
    btnCancelTxt: {
        color: colors.red,
        fontSize: responsiveFontSize(1.8),
        fontWeight: 'bold'
    },
    btnConfirm: {
        paddingHorizontal: '5%',
        paddingVertical: '2%',
        marginHorizontal: '2%'
    },
    btnConfirmTxt: {
        color: colors.zapColor,
        fontSize: responsiveFontSize(1.8),
        fontWeight: 'bold'
    }
});
