import AsyncStorage from '@react-native-async-storage/async-storage';

export function cleanData(){
  try {
    AsyncStorage.clear()
  } catch (error) {
    
  }
}

export async function removeItem(key){
  try {
    AsyncStorage.removeItem(key)
  } catch (error) {
    
  }
}

export async function storeData(key, value) {
    try {
      await AsyncStorage.setItem(key, value)
    } catch (e) {
      console.log(e)
    }
}

export async function storeDataObject(key,value) {
    try {
      const jsonValue = JSON.stringify(value);
      await AsyncStorage.setItem(key, jsonValue);
    } catch (e) {
      console.log(e)
      return 'fail'
    }
}


export async function getDataObject(key){
    try {
      const jsonValue = await AsyncStorage.getItem(key)
      return jsonValue != null ? JSON.parse(jsonValue) : null;
    } catch(e) {
      console.log(e)
    }
}


export async function getData(key) {
    try {
      const value = await AsyncStorage.getItem(key)
      return value;
    } catch(e) {
      console.log(e)
    }
}
  
  