import React from 'react'
import { ImageBackground, Image, Text, StyleSheet } from 'react-native'
import { responsiveFontSize, responsiveHeight, responsiveWidth } from 'react-native-responsive-dimensions'


export default function Header({business}){
    return(
        <ImageBackground source={{uri: business?.background}} style={styles.backImg}>
            <Image source={{uri: business?.logo}} style={styles.img}/>
            <Text style={styles.title}>{business?.name}</Text>
        </ImageBackground>
    )
}

const styles = StyleSheet.create({
    backImg: {
        width: responsiveFontSize(49),
        height: responsiveFontSize(20),
        alignItems: 'center',
        justifyContent: 'center',
        alignSelf: 'center'
    },
    img: {
        width: responsiveFontSize(10),
        height: responsiveFontSize(10),
        borderRadius: 100
    },
    title: {
        color: 'white',
        fontSize: responsiveFontSize(2),
        marginTop: responsiveWidth(2),
        fontWeight: 'bold'
    }
})
